/* eslint-disable graphql/template-strings */
import React, { useState, useEffect, useMemo, useCallback, PropsWithChildren } from "react"
import { useCore, useStorage } from "@app/hooks/useCore"
import axios from "axios"
import { graphql, useStaticQuery } from "gatsby"
import { useConfigContext } from "@app/providers/config"
import { v4 as uuid } from "uuid"
import { useCustomerContext } from "./customer"
import moment from "moment"
import { useAppContext } from "@providers/app"
import { useShopify } from "@hooks/useShopify"
import { useCookies } from "react-cookie"
import { useFunctions } from "@hooks/useFunctions"

// Note: To find functions quicker you can find there name below
type ContextProps = {
  state: any
  setVal: (keys: any, value: any) => void
  handleInput: (keys: any, defaultValue?: any) => (e: any) => void
  validateCreateStage1: () => void
  validateCreateStage2: () => void
  validateEditStage1: () => void
  validateEditStage2: () => void
  activeTab: () => number
  setActiveTab: (idx: number) => void
  getRegistry: (id: string) => Promise<void>
  getPublicRegistry: (id: string, token: any) => Promise<void>
  getEditRegistry: (id: string) => Promise<void>
  getRegistries: (getCode: boolean) => Promise<any>
  searchRegistries: (e: any, page?: number) => Promise<void>
  toggleAddToRegistryDrawer: (product: any, variant: any) => void
  saveProductToRegistry: (registryId: string) => void
  togglePasswordDrawer: (registryId: any, show: boolean) => void
  unlockRegistry: (e: any) => void
  deleteProductFromRegistry: (product: any, registry: any) => void
  editProductFromRegistry: (product: any, id: any) => void
  getRegistryProducts: (registryId: any) => Promise<any>
  deleteRegistry: () => Promise<void>
  customerRegistryPurchase: (addressPrefill: boolean) => Promise<void>
  enrichedGiftRegistries: any
}

export type PostOptions = {
  version?: number
}

// Paths object contains all paths for the swym API, further info can be found here:
// https://api.swymregistry.com/api-docs-external/#/ (To trst you will need to authorise)
const PATHS = {
  LIST_WITH_CONTENTS: "lists/fetch-list-with-contents",
  FETCH_LISTS: "lists/fetch-lists",
  CREATE_MULTIPLE: "lists/create-multiple",
  MARK_PUBLIC: "lists/markPublic",
  UPDATE: "lists/update",
  DELETE_LIST: "lists/update",
  FETCH_REGISTRIES: "shopperPrime/registry",
  UPDATE_LIST: "lists/update-ctx",
  UPDATE_MULTIPLE_CTX: "lists/update-multiple-ctx",
  USER_VALIDATE_SYNC: "lists/user-validate-sync",
  MODIFY_PRODUCTS: "shopperPrime/registry/modifyProducts",
  PRODUCT_QUANTITY: "shopperPrime/registry/productQuantity",
  REGISTRY_ARCHIVE: "shopperPrime/registry/archive",
  CUSTOMER_CHECKOUT: "storefront/checkout",
} as const

export const GiftRegistry = React.createContext<ContextProps | undefined>(undefined)

export const GiftRegistryProvider = ({ children }: PropsWithChildren) => {
  const { activeProduct, activeVariant } = useAppContext()
  const { getProducts } = useShopify()

  const {
    helpers: { decodeShopifyId, isBrowser, encodeShopifyId },
  } = useCore()
  const { getStorage, setStorage } = useStorage()
  const {
    store,
    settings: { keys },
  } = useConfigContext()
  const { customer } = useCustomerContext()

  const { callFunction } = useFunctions()

  const [giftRegistries, setGiftRegistries] = useState<any>([])
  const [enrichedGiftRegistries, setEnrichedGiftRegistries] = useState<any>(false)
  const [enriched, setEnriched] = useState<boolean>(false)
  const [fetched, setFetched] = useState<boolean>(false)

  const [cookies, setCookie] = useCookies(["grToken"])

  // This large state is used for a users and there registries data management throughout the feature
  // This is often referenced using the "setVal" and "getVal" function
  const [state, setState] = useState({
    userId: null,
    dbRegistries: [],
    editRegistry: {
      id: null,
      name: "",
      message: "",
      date: "",
      status: "public",
      password: "",
      imageURL: "",
      associatedListId: null,
      recipient: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        allowDirect: false,
        address: {
          address1: "",
          address2: "",
          city: "",
          province: "",
          country: "",
          zip: "",
          countryCode: "",
          phone: "",
        },
      },
    },
    registry: {
      name: "",
      message: "",
      date: "",
      status: "public",
      password: "",
      imageURL: "",
      recipient: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        allowDirect: false,
        address: {
          address1: "",
          address2: "",
          city: "",
          province: "",
          country: "",
          zip: "",
          countryCode: "",
          phone: "",
        },
      },
    },
    dbRegistry: null,
    dbRegistryPublic: null,
    selectedProduct: null,
    selectedProductVariant: null,
    search: {
      results: [],
      registreesFullName: null,
      registrys6DigitCode: null,
      errors: [],
    },
    tabs: ["active", "inactive", "inactive"],
    drawers: {
      addToRegistry: false,
      password: false,
    },
    modals: {
      shareRegistry: false,
    },
    passwordProtectedRegistry: null,
    passwordProtectionForm: {
      password: "",
      errors: [],
      valid: true,
      visible: false,
    },
    stage2: {
      errors: [],
      fields: {
        firstName: false,
        lastName: false,
        email: {
          valid: false,
        },
        phone: false,
        address: false,
      },
    },
    stage1: {
      errors: [],
      fields: {
        password: {
          valid: false,
          show: false,
        },
      },
    },
    activeTab: 1,
  })

  // Function used to set different values in the large state that holds all information
  const setVal = useCallback((keys: any, value: any) => {
    setState(prev => {
      const newVal: any = { ...prev }
      const keysSplit = keys.split(".")
      let curVal = newVal
      keysSplit.forEach((key: any, idx: any) => {
        if (idx === keysSplit.length - 1) {
          curVal[key] = value
        } else {
          curVal[key] = curVal[key] || {}
          curVal = curVal[key]
        }
      })
      return newVal
    })
  }, [])

  // Function used to get different valuies in the large state that holds all information
  const getVal = useCallback(
    (keys: any) => {
      const keysSplit = keys.split(".")
      let curVal: any = state
      let ret: any = undefined
      let stop: any = false
      keysSplit.forEach((key: any, idx: any) => {
        if (!stop) {
          if (idx === keysSplit.length - 1) {
            // curVal[key] = value
            ret = curVal[key]
          } else if (curVal[key]) {
            // curVal[key] = curVal[key] || {}
            curVal = curVal[key]
          } else {
            stop = true
          }
        }
      })
      return ret
    },
    [state]
  )

  const { plugins } = useStaticQuery<GatsbyTypes.SwymRegistryQuery>(graphql`
    query SwymRegistry {
      plugins: sanitySettingPlugins {
        swymGiftRegistryHost
        swymWishlistPlusHost
        swymWishlistPlusPid
        rangeUrl
      }
    }
  `)

  const swymWishlistPlusPid = useMemo(
    () => (plugins?.swymWishlistPlusPid ? encodeURIComponent(plugins?.swymWishlistPlusPid) : null),
    [plugins?.swymWishlistPlusPid]
  )

  const browseOurRange = useMemo(() => {
    return plugins?.rangeUrl || "/collections/essentials"
  }, [plugins])

  const hasMissingSwymKeys = useMemo(() => !plugins?.swymWishlistPlusHost || !plugins?.swymWishlistPlusPid, [plugins])

  const getRegId = useCallback(async () => {
    if (!isBrowser || hasMissingSwymKeys) return null

    try {
      const id = getStorage(keys.wishListUserId)
      if (id) {
        return id
      }

      const endpoint = `${plugins?.swymWishlistPlusHost}/v3/provider/checkAndGet?pid=${swymWishlistPlusPid}`

      const response = await axios
        .post(
          endpoint,
          new URLSearchParams({
            js_v: "3.0.6",
          })
        )
        .catch(err => {
          console.error(err)
          console.log("err?.response", err?.response)
        })

      const newRegId = response?.data?.get?.regid

      if (newRegId) {
        setStorage(keys.wishListUserId, newRegId)
        setVal("regId", newRegId)
        setVal("rangeUrl", plugins?.rangeUrl)
        return newRegId
      }
    } catch (e) {
      console.warn(e)
    }

    return null
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStorage, hasMissingSwymKeys, isBrowser, keys, plugins, swymWishlistPlusPid, setStorage])

  const setUserId = useCallback((userId: string) => setStorage(keys.giftRegistryUserId, userId), [keys, setStorage])

  const getUserId = useCallback(async () => {
    if (!isBrowser || hasMissingSwymKeys) return null

    try {
      const id = getStorage(keys.wishListUserId)
      if (id) {
        return id
      }

      const endpoint = `${plugins?.swymGiftRegistryHost}/v${options.version || 1}/${path}?pid=${swymWishlistPlusPid}`

      const response = await axios
        .post(
          endpoint,
          new URLSearchParams({
            js_v: "3.0.6",
          })
        )
        .catch(err => {
          console.error(err)
          console.log("err?.response", err?.response)
        })

      const newId = response?.data?.get?.regid
      if (newId) {
        setUserId(newId)
        return newId
      }
    } catch (e) {
      console.warn(e)
    }

    return null
  }, [getStorage, hasMissingSwymKeys, isBrowser, keys.wishListUserId, plugins?.swymGiftRegistryHost, setUserId, swymWishlistPlusPid])

  // Post request function used by other major API functions to generally add and delete products
  const post = useCallback(
    async ({ path, params = "", data = {}, options = {} }: { path: string; data?: any; options?: PostOptions }) => {
      if (hasMissingSwymKeys) return null
      // eslint-disable-next-line
      const endpoint = `${plugins?.swymGiftRegistryHost}/v${options.version || 1
        // eslint-disable-next-line
        }/${path}${params ? `?${params.toString()}&` : '?'}pid=${swymWishlistPlusPid}`

      const userId = await getUserId()
      const sessionId = uuid()

      const token = btoa(userId + ":" + sessionId)
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + token,
        "Referrer-Policy": "no-referrer",
      }

      try {
        const response = await axios.post(endpoint, data, { headers })

        return response
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [getUserId, hasMissingSwymKeys, plugins?.swymGiftRegistryHost, swymWishlistPlusPid]
  )

  const put = useCallback(
    async ({ path, params = {}, data = {}, options = {} }: { path: string; data?: any; options?: PostOptions }) => {
      if (hasMissingSwymKeys) return null

      // eslint-disable-next-line
      const endpoint = `${plugins?.swymGiftRegistryHost}/v${options.version || 1
        // eslint-disable-next-line
      }/${path}?${params.toString()}&pid=${swymWishlistPlusPid}`

      const userId = await getUserId()
      const sessionId = uuid()

      const token = btoa(userId + ":" + sessionId)
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + token,
        "Referrer-Policy": "no-referrer",
      }

      try {
        const response = await axios.put(endpoint, data, { headers })

        return response
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [getUserId, hasMissingSwymKeys, plugins?.swymGiftRegistryHost, swymWishlistPlusPid]
  )

  // Function used to add / update a product to the registry
  const saveProductToRegistry = useCallback(
    async (registryId: string, quantity: any, grProductData: any = {}) => {
      const productQuantity = quantity?.quantity?.quantity || quantity || 1

      const productId = decodeShopifyId(activeProduct?.id || state?.selectedProduct || grProductData?.id, "Product")
      const variantId = decodeShopifyId(activeVariant?.id || state?.selectedProductVariant, "ProductVariant")

      if (!registryId || !productId || !variantId) return

      const products = await getRegistryProducts(registryId)
      const existQuantity = products.find(product => product.epi === variantId)?.askQuantity || 0
      // Uses 2 calls, 1 to add the product and the other conditionally to update the quantity (due to swym API)
      const response = await post({
        path: PATHS.MODIFY_PRODUCTS,
        params: new URLSearchParams({
          registryId: registryId,
        }),
        data: {
          add: [
            {
              empi: productId,
              epi: variantId,
              du: `https://${store.shopifyShopDomain}/products/${activeProduct.handle}`,
              handle: activeProduct.handle,
            },
          ],
        },
      })
      if (productQuantity > 1) {
        const quantityResponse = await put({
          path: PATHS.PRODUCT_QUANTITY,
          params: new URLSearchParams({
            registryId: registryId,
          }),
          data: [
            {
              epi: variantId,
              askQuantity: productQuantity + existQuantity,
            },
          ],
        })
      }

      return true
    },
    [activeProduct, activeVariant, encodeShopifyId, post]
  )

  // Function used when you want to create or unlock a registry
  const giftPost = useCallback(
    async ({ path, data = {}, options = {} }: { path: string; data?: any; options?: any }) => {
      if (hasMissingSwymKeys) return null

      const endpoint = `${plugins?.swymGiftRegistryHost}/v${options.version || 1}/${path}?pid=${swymWishlistPlusPid}`

      const regid = await getRegId()
      const sessionid = uuid()
      const auth = btoa(regid + ":" + sessionid)
      try {
        const response = await axios
          .post(
            endpoint,
            {
              ...data,
            },
            {
              headers: {
                authorization: "Basic " + auth,
              },
            }
          )
          .catch(err => {
            console.error(err)
            console.log("err?.response", err?.response)
            return "error"
          })

        return response
      } catch (e) {
        return null
      }
    },
    [getRegId, hasMissingSwymKeys, plugins, swymWishlistPlusPid]
  )

  // Function used when you want to edit/update a registry
  const giftPut = useCallback(
    async ({ path, data = {}, options = {}, registryId = null }: { path: string; data?: any; options?: any; registryId?: any }) => {
      if (hasMissingSwymKeys) return null

      //eslint-disable-next-line
      const endpoint = `${plugins?.swymGiftRegistryHost}/v${options.version || 1
        //eslint-disable-next-line
        }/${path}?registryId=${registryId}&pid=${swymWishlistPlusPid}`

      const regid = await getRegId()
      const sessionid = uuid()
      const auth = btoa(regid + ":" + sessionid)
      try {
        const response = await axios
          .put(
            endpoint,
            {
              ...data,
            },
            {
              headers: {
                authorization: "Basic " + auth,
              },
            }
          )
          .catch(err => {
            console.error(err)
            console.log("err?.response", err?.response)
          })

        return response
      } catch (e) {
        return null
      }
    },
    [getRegId, hasMissingSwymKeys, plugins, swymWishlistPlusPid]
  )

  // Function used for searching registries
  const giftGet = useCallback(
    async ({ path, data = {}, options = {} }: { path: string; data?: any; options?: any }) => {
      if (hasMissingSwymKeys) return null
      const endpoint = `${plugins?.swymGiftRegistryHost}/v${options.version || 1}/${path}?pid=${swymWishlistPlusPid}`
      const regid = await getRegId()
      const sessionid = uuid()
      const auth = btoa(regid + ":" + sessionid)
      try {
        const response = await axios.get(endpoint, {
          params: {
            ...data,
          },
          headers: {
            authorization: "Basic " + auth,
          },
        })

        return response
      } catch (e) {
        //eslint-disable-next-line
        return e?.response
      }
    },
    [getRegId, hasMissingSwymKeys, plugins, swymWishlistPlusPid]
  )

  const login = useCallback(
    async (customer: any) => {
      if (customer && customer?.id) {
        const shopifyId = decodeShopifyId(customer?.id, "Customer")
        if (!shopifyId) return false
        const regId = (
          await post({
            path: PATHS.USER_VALIDATE_SYNC,
            data: {
              platform: "shopify",
              extuid: shopifyId,
            },
          })
        )?.data?.regid

        if (!regId) return false
        setStorage(keys.wishListUserId, regId)
        setVal("regId", regId)
        return true
      }
      return false
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [setStorage, post, decodeShopifyId, keys]
  )

  const searchRegistries = useCallback(
    async (e: any, page = 0) => {
      if (e) {
        e.preventDefault()
      }
      const errors = []
      if (!state.search.registreesFullName && !state.search.registrys6DigitCode) {
        errors.push('Please enter either a "Registrees Full Name" or a "Registrys 6 Digit Code"')
      }
      setVal("search.errors", errors)

      if (!errors.length) {
        // search for registries
        const resp = await giftGet({
          path: "storefront/searchRegistry",
          data: {
            searchString: state.search.registreesFullName || state.search.registrys6DigitCode,
            page: page,
            pageSize: 10,
          },
        })

        if (resp && resp.data) {
          setVal("search.success", true)
          if (page === 0) {
            setVal("search.results", resp.data.results)
          } else {
            setVal("search.results", [...state.search.results, ...resp.data.results])
          }
          setVal("search.resultsCount", resp.data.totalResultsCount)
          setVal("search.pages", resp.data.totalPageCount)
          if (page + 1 < resp.data.totalPageCount) {
            searchRegistries(null, page + 1)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, giftGet]
  )

  const handleInput = useCallback(
    (keys: any, defaultValue: any) => {
      if (typeof defaultValue !== "undefined") {
        return setVal(keys, defaultValue)
      }

      return (v: any) => {
        let save = true
        if (keys === "registry.message") {
          if (v.target.value.length > 150) {
            save = false
          }
        }
        if (save) {
          setVal(keys, v.target.value)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVal]
  )

  const setActiveTab = useCallback(
    (tab: number) => {
      Object.values(state.tabs).forEach((dw, idx) => {
        if (idx === tab) {
          state.tabs[idx] = "active"
        } else {
          state.tabs[idx] = "complete"
        }
      })
      setVal("tabs", state.tabs)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  const activeTab = useCallback(() => {
    return state.tabs.findIndex(tab => tab === "active")
  }, [state])

  const createRegistry = useCallback(async () => {
    const mode = state.registry.password ? "private" : "public"
    const data = {
      registryName: state.registry.name,
      creatorName: state.registry.recipient.firstName + " " + state.registry.recipient.lastName,
      coCreatorName: "",
      email: customer.email,
      description: state.registry.message,
      expiryDate: state.registry.date === "" ? moment(new Date()).format("yyyy-MM-DD") : moment(state.registry.date).format("yyyy-MM-DD"),
      settings: {
        isPublic: mode === "public",
        allowMultipleGifting: true,
        isPasswordProtected: mode === "public" ? false : true,
      },
      customProps: [
        {
          allowDirect: state.registry.recipient.allowDirect,
          phone: state.registry.recipient.phone,
        },
      ],
      mode: "public",
      password: mode === "private" ? state.registry.password : "",
      address: {
        firstName: state.registry.recipient.firstName,
        lastName: state.registry.recipient.lastName,
        address1: state.registry.recipient.address.address1,
        address2: "",
        city: state.registry.recipient.address.city,
        province: state.registry.recipient.address.province,
        zip: state.registry.recipient.address.zip,
        country: state.registry.recipient.address.country,
        countryCode: state.registry.recipient.address.countryCode,
        phone: state.registry.recipient.address.phone || "None",
      },
      associatedListId: "",
      occasion: "",
      imageURL: state.registry.imageURL,
    }

    const resp = await giftPost({
      path: "shopperPrime/registry",
      data,
    })

    if (resp?.status === 200 && resp?.data?.Id) {
      return resp.data.Id
    } else {
      return false
    }
  }, [state, customer, giftPost])

  const editRegistry = useCallback(async () => {
    const mode = state.editRegistry.status === "private" && state.editRegistry.password ? "private" : "public"
    const data = {
      registryName: state.editRegistry.name,
      creatorName: state.editRegistry.recipient.firstName + " " + state.editRegistry.recipient.lastName,
      coCreatorName: "",
      email: customer?.email,
      description: state.editRegistry.message,
      expiryDate: moment(state.editRegistry.date).format("yyyy-MM-DD"),
      settings: {
        isPublic: mode === "public",
        allowMultipleGifting: true,
        isPasswordProtected: mode === "public" ? false : true,
      },
      customProps: [
        {
          allowDirect: state.editRegistry.recipient.allowDirect,
          phone: state.editRegistry.recipient.phone,
        },
      ],
      mode: "public",
      password: mode === "private" ? state.editRegistry.password : "",
      address: {
        firstName: state.editRegistry.recipient.firstName,
        lastName: state.editRegistry.recipient.lastName,
        address1: state.editRegistry.recipient.address.address1 || "",
        address2: "",
        city: state.editRegistry.recipient.address.city || "",
        province: state.editRegistry.recipient.address.province || "",
        zip: state.editRegistry.recipient.address.zip || "",
        country: state.editRegistry.recipient.address.country || "",
        countryCode: state.editRegistry.recipient.address.countryCode || "",
        phone: state.editRegistry.recipient.address.phone || "None",
      },
      associatedListId: state.editRegistry.associatedListId || "",
      occasion: "",
      imageURL: state.editRegistry.imageURL || " ",
    }

    const resp = await giftPut({
      path: "shopperPrime/registry",
      data,
      registryId: state.editRegistry.id,
    })
    if (resp?.status === 200 && resp?.data?.Id) {
      return resp.data.Id
    } else {
      return false
    }
  }, [state, customer, giftPut])

  // Function used to validate stage one when user creates there registry
  const validateCreateStage1 = useCallback(() => {
    const { registry } = state
    const errors: any = []
    const fields = {
      name: false,
      date: false,
      password: {
        valid: false,
      },
    }
    if (!registry.name) {
      errors.push("Please enter a name for your registry")
      fields.name = false
    } else {
      fields.name = true
    }
    if (registry.date) {
      fields.date = true
    } else {
      // errors.push("Please enter a date for your registry")
      // fields.date = false

      // This form doesnt have a date field?
      fields.date = true
    }
    if (registry.password) {
      registry.status = "private"
    } else {
      registry.status = "public"
    }
    fields.password.valid = true

    setVal("stage1.errors", errors)
    setVal("stage1.fields", fields)
    setVal("registry", registry)

    if (!errors.length) {
      setVal("tabs.0", "complete")
      setVal("tabs.1", "active")
    } else {
      setVal("tabs.0", "active")
      setVal("tabs.1", "inactive")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  // Function used to validate stage two when user creates there registry
  const validateCreateStage2 = useCallback(async () => {
    const { registry } = state
    const errors: any = []
    const fields = {
      firstName: false,
      lastName: false,
      email: {
        valid: false,
      },
      phone: false,
      allowDirect: false,
      address: false,
    }

    if (!registry.recipient.firstName) {
      errors.push("Please enter your first name")
      fields.firstName = false
    } else {
      fields.firstName = true
    }
    if (!registry.recipient.lastName) {
      fields.lastName = false
      errors.push("Please enter your last name")
    } else {
      fields.lastName = true
    }
    const filter = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

    if (filter.test(registry.recipient.email)) {
      fields.email.valid = true
    } else {
      errors.push("Please enter a valid email")
      fields.email.valid = false
    }

    if (registry?.recipient?.allowDirect) {
      fields.allowDirect = true
    }

    if (registry.recipient.address) {
      fields.address = true
    } else {
      errors.push("Please select an address")
      fields.address = false
    }

    setVal("stage2.errors", errors)
    setVal("stage2.fields", fields)
    setVal("registry", registry)

    if (!errors.length) {
      const resp = await createRegistry()
      if (resp) {
        window.location.href = "/account/gift-registry/view?id=" + resp
      } else {
        setVal("stage2.errors", ["Something went wrong creating registry. Please try again."])
      }
      // redirect
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, createRegistry])

  // Function used to Edit stage one when user creates there registry
  const validateEditStage1 = useCallback(async () => {
    const { editRegistry: registry } = state
    const errors: any = []
    const fields = {
      name: false,
      date: false,
      password: {
        valid: false,
      },
    }
    if (!registry.name) {
      errors.push("Please enter a name for your registry")
      fields.name = false
    } else {
      fields.name = true
    }
    if (registry.date) {
      fields.date = true
    } else {
      errors.push("Please enter a date for your registry")
      fields.date = false
    }
    if (registry.status === "private" && !registry.password) {
      errors.push("Please enter a password for your registry")
      fields.password.valid = false
    } else if (registry.status === "public") {
      registry.password = ""
    } else {
      fields.password.valid = true
    }
    setVal("stage1.errors", errors)
    setVal("stage1.fields", fields)
    setVal("editRegistry", registry)

    if (!errors.length) {
      setVal("tabs.0", "complete")
      setVal("tabs.1", "active")
    } else {
      setVal("tabs.0", "active")
      setVal("tabs.1", "inactive")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, editRegistry])

  // Function used to edit stage two when user creates there registry
  const validateEditStage2 = useCallback(async () => {
    const { editRegistry: registry } = state
    const errors: any = []
    const fields = {
      name: false,
      date: false,
      password: {
        valid: false,
      },
      firstName: false,
      lastName: false,
      email: {
        valid: false,
      },
      phone: false,
      allowDirect: false,
      address: false,
    }
    if (!registry.name) {
      errors.push("Please enter a name for your registry")
      fields.name = false
    } else {
      fields.name = true
    }
    if (registry.date) {
      fields.date = true
    } else {
      errors.push("Please enter a date for your registry")
      fields.date = false
    }
    if (registry.status === "private" && !registry.password) {
      errors.push("Please enter a password for your registry")
      fields.password.valid = false
    } else if (registry.status === "public") {
      registry.password = ""
    } else {
      fields.password.valid = true
    }
    if (!registry.recipient.firstName) {
      errors.push("Please enter your first name")
      fields.firstName = false
    } else {
      fields.firstName = true
    }
    if (!registry.recipient.lastName) {
      fields.lastName = false
      errors.push("Please enter your last name")
    } else {
      fields.lastName = true
    }
    const filter = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

    if (filter.test(registry.recipient.email)) {
      fields.email.valid = true
    } else {
      errors.push("Please enter a valid email")
      fields.email.valid = false
    }

    if (registry.recipient.phone) {
      fields.phone = true
    } else {
      // errors.push("Please enter a phone number")
      fields.phone = false
    }

    if (registry?.recipient?.allowDirect) {
      fields.allowDirect = true
    }

    if (registry.recipient.address) {
      fields.address = true
    } else {
      // errors.push("Please select an address")
      fields.address = false
    }

    setVal("stage1.errors", errors)
    setVal("stage1.fields", fields)
    setVal("stage2.errors", errors)
    setVal("stage2.fields", fields)
    setVal("editRegistry", registry)
    setVal("tabs.0", "complete")
    setVal("tabs.1", "active")

    if (!errors.length) {
      const resp = await editRegistry()
      if (resp) {
        window.location.href = "/account/gift-registry/view?id=" + resp
      } else {
        setVal("stage2.errors", ["Something went wrong editing registry. Please try again."])
      }
      // redirect
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, editRegistry])

  const getRegistry = useCallback(
    async (id: any) => {
      const resp = await giftGet({
        path: "shopperPrime/registry",
        data: {
          registryId: id,
          userEmail: customer.email,
          includeListProducts: true,
          // includeOrders: true,
        },
      })
      if (resp && resp.status === 200) {
        setVal("dbRegistry", resp.data)
        return resp.data
      } else if (customer && id && customer.email) {
        const resp = await giftGet({
          path: "shopperPrime/registry",
          data: {
            registryId: id,
            userEmail: customer.email,
          },
        })

        if (resp && resp.status === 200) {
          setVal("dbRegistry", resp.data)
          return resp.data
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer, giftGet]
  )

  const getEditRegistry = useCallback(
    async (id: any) => {
      const resp = await giftGet({
        path: "shopperPrime/registry",
        data: {
          registryId: id,
          userEmail: customer.email,
          includeListProducts: true,
          // includeOrders: true,
        },
      })
      let data = null
      if (resp && resp.status === 200) {
        data = resp.data
      } else if (customer && id && customer.email) {
        const resp = await giftGet({
          path: "shopperPrime/registry",
          data: {
            registryId: id,
            userEmail: customer.email,
          },
        })
        if (resp && resp.status === 200) {
          data = resp.data
        }
      }
      if (data) {
        const registry = {
          id: data.Id,
          name: data.registryName,
          message: data.description,
          date: data.expiryDate,
          status: data.settings.isPasswordProtected ? "private" : "public",
          imageURL: data.imageURL || null,
          password: data.password,
          recipient: {
            firstName: data.address.firstName,
            lastName: data.address.lastName,
            email: data.email,
            phone: null,
            allowDirect: false,
            addressComplete: `${data.address.address1} ${data.address.address2} ${data.address.city}, ${data.address.province} ${data.address.zip}, ${data.address.country}`,
            address: {
              address1: data.address.address1,
              address2: data.address.address2,
              city: data.address.city,
              province: data.address.province,
              zip: data.address.zip,
              country: data.address.country,
              countryCode: data.address.countryCode,
              phone: data.address.phone || "None",
            },
          },
          associatedListId: data.associatedListId,
        }
        setVal("editRegistry", registry)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer, giftGet]
  )
  const getPublicRegistry = useCallback(
    async (id: any, token: any) => {
      const resp = await giftGet({
        path: "storefront/registry",
        data: {
          registryId: id,
          includeListProducts: true,
          token,
        },
      })

      if (resp && resp.status === 200) {
        setVal("dbRegistryPublic", resp.data)
        return resp.data
      } else if (resp && resp.status === 401 && resp.data.includes("Auth token must be provided for password protected registries")) {
        togglePasswordDrawer(id, true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [giftGet]
  )

  const getRegistries = useCallback(async (getCode: boolean) => {
    const resp = await giftGet({
      path: "shopperPrime/registry",
      data: {
        userEmail: customer.email,
        includeListProducts: true,
        includeOrders: true,
      },
    })

    if (resp && resp.status === 200) {
      setVal("dbRegistries", resp.data)

      return resp?.data
    }
    if (getCode) {
      return resp
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, giftGet])

  const toggleAddToRegistryDrawer = useCallback(
    (product: any, variant: any) => {
      setVal("selectedProduct", product?.id)
      setVal("selectedProductVariant", variant?.id)
      setVal("drawers.addToRegistry", !state?.drawers?.addToRegistry)
    },
    [setVal, state]
  )

  const togglePasswordDrawer = useCallback(
    (registryId: any, show: boolean) => {
      if (registryId) {
        setVal("passwordProtectedRegistry", registryId)
      }
      if (show === true || show === false) {
        setVal("drawers.password", show)
      } else {
        setVal("drawers.password", !state?.drawers?.password)
      }
    },
    [setVal, state]
  )

  const unlockRegistry = useCallback(
    async (e: any, id: any) => {
      if (e) {
        e.preventDefault()
      }

      const password = state.passwordProtectionForm.password
      const registryId = id ? id : state.passwordProtectedRegistry

      if (password && registryId) {
        const resp = await giftPost({
          path: "storefront/registry/generateAuthToken",
          data: {
            registryId,
            password,
          },
        })

        if (resp?.status === 200) {
          // expire in 60sec
          setCookie("grToken", resp.data, { path: "/gift-registry", expires: new Date(Date.now() + 60000) })
          setVal("drawers.password", false)
          return resp
        }
        if (resp == "error") {
          return resp
        }
      }
    },
    [setVal, state, giftPost, setCookie]
  )

  const updateRegistry = useCallback(
    async (registry: any) => {
      const resp = await giftPut({
        path: "shopperPrime/registry",
        data: {
          associatedListId: registry.associatedListId,
        },
        registryId: registry.Id,
      })

      if (resp && resp.data) {
        return true
      }
      return false
    },
    [giftPut]
  )

  const createWishlist = useCallback(
    async (registry: any) => {
      const resp = await post({
        path: "lists/create",
        data: {
          lname: registry.registryName,
        },
      })

      if (resp && resp.data) {
        const lid = resp.data.lid
        registry.associatedListId = lid
        const updateResp = await updateRegistry(registry)
        if (updateResp) {
          return lid
        }
      }
      return false
    },
    [post, updateRegistry]
  )

  const maybeMakeRegistryWishlist = useCallback(
    async (registry: any) => {
      if (!registry?.associatedListId) {
        const resp = await createWishlist(registry)

        if (resp) {
          return resp
        }
        return false
      } else {
        return registry.associatedListId
      }
    },
    [createWishlist]
  )

  const deleteRegistry = useCallback(
    async (registry: any, registryListData: any) => {
      const resp = await callFunction("archive-registry", {
        path: PATHS?.REGISTRY_ARCHIVE,
        data: {
          isArchived: true,
          registryIds: [parseInt(registry?.Id)],
        },
      })

      if (resp?.status == "success") {
        const registryToRemove = registryListData?.findIndex(registryData => registryData.Id === registry?.Id)
        if (registryToRemove !== -1) {
          registryListData?.splice(registryToRemove, 1)
          setGiftRegistries(registryListData)
          setEnrichedGiftRegistries([...registryListData])
        }
      }
    },
    [callFunction]
  )

  // Function used for customer to checkout and buy products from someones registry
  const customerRegistryPurchase = useCallback(
    async (addressPrefill: boolean, registryItemsInCart: any, cart: any) => {
      if (!registryItemsInCart) return
      if (registryItemsInCart?.length === 0) return

      let gifter = {}
      const registryPurchaseProductList = registryItemsInCart.map(item => {
        let hasGiftAttribute = false;
        if (item?.attributes.length) {
          const info = item.attributes.find(attr => attr.key === "gifter")
          gifter = info?.value ? JSON.parse(info.value) : {}
          if (item.attributes.find(attr => attr.key === "gifter") || item.attributes.find(attr => attr.key === "registryProduct")) {
            hasGiftAttribute = true;
          }
        }
        const data = {
          epi: Number(decodeShopifyId(item.merchandise?.id, "ProductVariant")) || null,
          qty: item.quantity || 1,
          customProperties: hasGiftAttribute
            ? [
                {
                  key: "Gift registry",
                  value: gifter?.registryName || "",
                },
              ]
            : item?.attributes || [],
        }
        return data
      })

      // Checks to see if one of the items in the cart has the click and collect option set to true so that click and collect shows as the shipping option in the checkout.
      const clickAndCollectOption = cart.attributes.some(attr => attr.key === "_click_and_collect" && attr.value === "true")

      const customAttributes = []
      if (clickAndCollectOption) {
        customAttributes.push(
          ...cart.attributes.map(attr => {
            const { __typename, ...rest } = attr
            return rest
          })
        )
      }

      const gifterNoAddress = {
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        zip: "",
        country: "",
        countryCode: "",
        phone: "",
      }

      // Need to add new SWYM API data for address and add conditional based on addressPrefill value
      const response = await callFunction("checkout-giftregistry", {
        path: PATHS.CUSTOMER_CHECKOUT,
        data: {
          email: customer ? customer.email : gifter?.email,
          note: "",
          custom_attributes: customAttributes,
          shipping_line: {
            shippingRateHandle: "",
            price: "",
          },
          product_list: registryPurchaseProductList,
          // address: gifter?.address?.address1 != "" && addressPrefill == true ? gifter.address : gifterNoAddress,
          address: gifterNoAddress,
        },
        id: gifter?.id,
      })

      return response
    },
    [callFunction, customer, decodeShopifyId]
  )

  useEffect(() => {
    if (customer) {
      login(customer)
    } else {
      setVal("dbRegistries", [])
      setFetched(false)
      setEnrichedGiftRegistries(false)
      setGiftRegistries([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  const getRegistryProducts = useCallback(
    async (registryId: any) => {
      if (registryId && customer) {
        const resp = await giftGet({
          path: "shopperPrime/registry",
          data: {
            registryId,
            userEmail: customer.email,
            includeListProducts: true,
          },
        })

        if (resp && resp.status === 200) {
          return resp.data.associatedListedProducts
        }
      }
      return []
    },
    [customer, giftGet]
  )

  const addRegistryProduct = useCallback(
    async (registryId: any) => {
      if (registryId) {
        const path = "shopperPrime/registry"
        // const path = customer ? "shopperPrime/registry" : "storefront/registry"
        const resp = await giftGet({
          path,
          data: {
            registryId,
            userEmail: customer.email || "",
            includeListProducts: true,
          },
        })

        if (resp && resp.status === 200) {
          let registry = resp.data
          const handles = registry?.associatedListedProducts?.map(({ handle }: string) => handle)
          const rawShopifyProducts = handles?.length ? await getProducts({ firstImages: 1, firstVariants: 1, handles: handles }) : []
          if (rawShopifyProducts?.length) {
            registry = { ...registry, enrichedProducts: rawShopifyProducts }
          }
          return registry
        }
      }
      return {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer]
  )

  const editProductFromRegistry = useCallback(
    async (product: any, id: any, quantity: any) => {
      const registryId = id
      const variantId = product?.epi

      if (registryId && variantId) {
        await put({
          path: PATHS.PRODUCT_QUANTITY,
          params: new URLSearchParams({
            registryId: registryId,
          }),
          data: [
            {
              askQuantity: quantity,
              epi: variantId,
            },
          ],
        })
        setEnriched(false)
      }
    },
    [put]
  )

  const triggerEnrichProducts = useCallback(() => {
    setEnriched(false)
  }, [setEnriched])

  const deleteProductFromRegistry = useCallback(
    async (product: any, registry: any) => {
      const registryId = registry?.Id
      const variantId = product?.epi
      const productId = product?.empi
      const productDu = product?.du
      const productDt = product?.dt

      if (registryId && variantId && productId && productDu) {
        // const lid = await maybeMakeRegistryWishlist(registry)
        const resp = await post({
          path: PATHS.MODIFY_PRODUCTS,
          params: new URLSearchParams({
            registryId: registryId,
          }),
          data: {
            delete: [
              {
                empi: productId,
                epi: variantId,
                du: `https://${store.shopifyShopDomain}/products/${activeProduct.handle}`,
                handle: activeProduct.handle,
              },
            ],
          },
        })

        if (resp && resp.data) {
          // remove product from registry list
          const registryProducts = registry?.associatedListedProducts || []
          const newRegistryProducts = registryProducts.filter((p: any) => p.epi != variantId && p.dt != productDt)
          registry.associatedListedProducts = newRegistryProducts
          setVal("dbRegistry", registry)
        }
      } else {
        console.error("Error deleteProductFromtRegistry. No registryId or variantId or productId or productDu")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [post, maybeMakeRegistryWishlist]
  )

  // need to make sure this actually works
  useEffect(() => {
    const initGiftRegistries = async () => {
      const registries = await getRegistries(true)
      if(registries != undefined && registries?.length !== undefined) {
        setGiftRegistries(registries == undefined ? [] : registries)
        setFetched(true)
        setEnriched(false)
        setEnrichedGiftRegistries(false)
      } else {
        initGiftRegistries()
      }
    }
    if (customer?.email && !fetched) {
      initGiftRegistries()
    }
  }, [getRegistries, customer?.email, fetched])

  useEffect(() => {
    const initGiftRegistriesEnrichment = async () => {
      setEnriched(true)
      const enrichedLists = await Promise.all(
        giftRegistries?.map(async registry => {
          const registryList = await getRegistry(registry.Id)
          const handles = registryList?.associatedListedProducts?.map(({ handle }: string) => handle)
          const rawShopifyProducts = handles?.length ? await getProducts({ firstImages: 1, firstVariants: 1, handles: handles }) : []
          const registryEnrichedList = rawShopifyProducts?.length
            ? Object.assign({}, registryList, { enrichedProducts: rawShopifyProducts })
            : registryList
          return registryEnrichedList ?? []
        })
      )
      setEnrichedGiftRegistries(enrichedLists)
    }

    if (customer?.email && fetched && !enriched) {
      if (giftRegistries?.length) {
        initGiftRegistriesEnrichment()
      } else {
        setEnrichedGiftRegistries(false)
        setEnriched(true)
      }
    }
  }, [customer?.email, enriched, getProducts, getRegistry, giftRegistries, fetched])



  const contextValue = React.useMemo<ContextProps>(
    () => ({
      state,
      setVal,
      handleInput,
      validateCreateStage1,
      validateCreateStage2,
      validateEditStage1,
      validateEditStage2,
      activeTab,
      setActiveTab,
      getRegistry,
      getEditRegistry,
      getPublicRegistry,
      getRegistries,
      searchRegistries,
      toggleAddToRegistryDrawer,
      saveProductToRegistry,
      togglePasswordDrawer,
      unlockRegistry,
      getRegistryProducts,
      deleteProductFromRegistry,
      editProductFromRegistry,
      deleteRegistry,
      enrichedGiftRegistries,
      browseOurRange,
      addRegistryProduct,
      triggerEnrichProducts,
      customerRegistryPurchase,
    }),
    [
      state,
      setVal,
      handleInput,
      validateCreateStage1,
      validateCreateStage2,
      validateEditStage1,
      validateEditStage2,
      activeTab,
      setActiveTab,
      getRegistry,
      getEditRegistry,
      getPublicRegistry,
      getRegistries,
      searchRegistries,
      toggleAddToRegistryDrawer,
      saveProductToRegistry,
      togglePasswordDrawer,
      unlockRegistry,
      getRegistryProducts,
      deleteProductFromRegistry,
      editProductFromRegistry,
      deleteRegistry,
      enrichedGiftRegistries,
      browseOurRange,
      addRegistryProduct,
      triggerEnrichProducts,
      customerRegistryPurchase,
    ]
  )

  return <GiftRegistry.Provider value={contextValue}>{children}</GiftRegistry.Provider>
}

export const useGiftRegistryContext = (): ContextProps => ({ ...React.useContext(GiftRegistry) }) as ContextProps
