import { useMemo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useRoutes } from "@hooks/useRoutes"
import { useCore } from "@hooks/useCore"
import { usePromotions } from "@hooks/usePromotions"

export const useNavigation = () => {
  const { urlResolver } = useRoutes()
  const { menu } = usePromotions()
  const {
    helpers: { edgeNormaliser },
  } = useCore()

  const { main, all } = useStaticQuery<GatsbyTypes.StaticMenuQuery>(graphql`
    query StaticMenu {
      main: sanitySettingMenus {
        account {
          items: _rawItems(resolveReferences: { maxDepth: 2 })
          handle {
            current
          }
        }
        header {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
          handle {
            current
          }
        }
        headerMobile {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
          handle {
            current
          }
        }
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          handle {
            current
          }
        }
        generic {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          handle {
            current
          }
        }
      }
      all: allSanityNavigation(filter: { handle: { current: { nin: ["account", "header", "headerMobile", "footer"] } } }) {
        edges {
          node {
            items: _rawItems(resolveReferences: { maxDepth: 6 })
            handle {
              current
            }
          }
        }
      }
    }
  `)

  const buildItems: any = useCallback(
    (items: any) =>
      items?.map((link: any) => {
        const type = link?._type?.replace("navigation", "").toLowerCase()
        const internal = ["link", "sub"].includes(type)
        const item = !internal ? link[type] || link.link : false
        const items = buildItems(link?.items) || false
        const resolvedUrl = urlResolver(item)
        const title = link?.title || resolvedUrl?.title
        const image = link?.image || resolvedUrl?.image
        const url = !internal ? resolvedUrl?.url : link.link || false
        const accent = link?.accent || "none"
        const showSocialIcons = link?.showSocialIcons
        const toggleCTAButton = link?.toggleCTAButton === false ? false : true

        return {
          type,
          title,
          key: link._key,
          showSocialIcons,
          toggleCTAButton,
          url: typeof url !== "string" ? urlResolver(url)?.url : url,
          image: image?.asset?.url || image?.url || image,
          accent,
          ...(item && { item }),
          ...(items && { items }),
        }
      }) || [],
    [urlResolver]
  )

  const headerDesktop = useMemo(
    () =>
      buildItems(
        menu === "default"
          ? main?.header?.items
          : edgeNormaliser(all)?.find(({ handle }: { handle: any }) => handle?.current === menu)?.items
      ),
    [menu, main, all, buildItems, edgeNormaliser]
  )
  
  const headerMobile = useMemo(
    () =>
      buildItems(
        menu === "default"
          ? main?.headerMobile?.items
          : edgeNormaliser(all)?.find(({ handle }: { handle: any }) => handle?.current === menu)?.items
      ),
    [menu, main, all, buildItems, edgeNormaliser]
  )

  const footer = useMemo(() => buildItems(main?.footer?.items), [main, buildItems])

  const account = useMemo(() => buildItems(main?.account?.items), [main, buildItems])

  const generic = useMemo(() => buildItems(main?.generic?.items), [buildItems, main?.generic?.items])

  return { headerDesktop, headerMobile, footer, account, generic }
}