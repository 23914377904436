import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { useStorage, useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useConfigContext } from "@app/providers/config"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useCustomerContext } from "@app/providers/customer"
import { useFunctions } from "@app/hooks/useFunctions"
import { CART_LINE_REPLACE } from "@app/dotheadless/graphql/mutations/cart"
import { useGiftRegistryContext } from "@app/providers/giftRegistry"

type ContextProps = {
  id: string
  url: string
  count: number
  cart: any
  countryCode: string
  currencyCode: string
  loading: boolean
  setLoading: (loading: boolean) => void
  gotoCheckout: (event?: MouseEvent) => void
  saveCart: (cart: any) => void
  createCart: (countryCode: string | undefined, forceNew: boolean) => void
}

export const CartContext = React.createContext<ContextProps | undefined>(undefined)

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    graphql: {
      mutations: { CART_CREATE },
      queries: { GET_CART },
    },
  } = useCore()

  const [lineItemsReplace] = useMutation(CART_LINE_REPLACE)
  const { getStorage, setStorage, removeStorage } = useStorage()
  const { store, settings } = useConfigContext()
  const { decorateUrl } = useAnalytics()
  const { cartNormaliser } = useShopify()
  const { refetch: getCartQuery } = useQuery(GET_CART, { fetchPolicy: "no-cache", skip: true })
  const [cartCreate] = useMutation(CART_CREATE)
  const [cart, setCart] = useState<any | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { customer } = useCustomerContext()
  const { callFunction } = useFunctions()
  const { customerRegistryPurchase } = useGiftRegistryContext()

  const id = useMemo(() => cart?.id || getStorage(settings.keys.cart), [getStorage, settings.keys.cart, cart?.id])

  const url = useMemo(
    () => (cart?.checkoutUrl ? decorateUrl(cart.checkoutUrl.replace(store.shopifyShopDomain, store.shopifyCheckoutUrl)) : ""),
    [cart, store, decorateUrl]
  )

  const countryCode = useMemo(
    () => cart?.buyerIdentity?.countryCode || getStorage(settings.keys.market) || "AU",
    [getStorage, settings.keys.market, cart?.buyerIdentity?.countryCode]
  )

  const currencyCode = useMemo(() => (
    cart?.cost?.totalAmount?.currencyCode && cart.cost.totalAmount.currencyCode !== "XXX" ? cart.cost.totalAmount.currencyCode : "AUD"
  ), [cart?.cost?.totalAmount?.currencyCode])

  const count = useMemo(
    () =>
      cart?.totalQuantity, // cart?.lines?.reduce((count: number, lineItem: any, i: number) => (i ? count + lineItem.quantity : lineItem.quantity), 0) || 0,
    [cart]
  )

  useEffect(() => {
    createCart(store.locationRegion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCart = useCallback(async () => {
    try {
      if (id) {
        const countryCode = getStorage(settings.keys.market) || store.locationRegion
        setLoading(true)
        const {
          data: { cart },
        } = await getCartQuery({ countryCode, cartId: id })
        setLoading(false)
        return cartNormaliser(cart)
      }
      return false
    } catch (e) {
      setLoading(false)
      console.error((e as Error).message)
    }
  }, [id, settings.keys.market, store.locationRegion, getCartQuery, getStorage])

  const saveCart = useCallback(
    // (cart: any) => {

    //   try {
    //     const normalisedCart = cartNormaliser(cart)
    //     const newCart = { ...normalisedCart, lines: normalisedCart.lines }
    //     setCart(newCart)
    //     setStorage(settings.keys.cart, newCart.id)
    //     setStorage(settings.keys.market, newCart.buyerIdentity?.countryCode)
    //   } catch (e) {
    //     console.error((e as Error).message)
    //   }
    // },
    (cart: any) => {
      try {         
        const checkoutCC = cart?.attributes?.find((attr: any) => attr.key === "_click_and_collect" && attr.value === "true")
        const lineItemCC = cart?.lines?.edges?.find(
          (item: any) => item?.node?.attributes?.find((attr: any) => attr.key === "_click_and_collect" && attr.value === "true")
        )

        const hasLineItems = cart?.lines?.edges?.length
        let requiresSaving = false

        const lineItemsToSave = cart?.lines?.edges?.map((item: any) => {
          return {
            merchandiseId: item?.node?.merchandise?.id,
            id: item?.node?.id,
            attributes: [...item?.node?.attributes.map((attr: any) => ({ key: attr.key, value: attr.value }))],
            quantity: item?.node?.quantity,
          }
        })

        if (checkoutCC && !lineItemCC && hasLineItems) {
          const firstLineItem = lineItemsToSave[0]
          const clickAndCollectAttribute = firstLineItem?.attributes?.find((attr: any) => attr.key === "_click_and_collect")
          if (clickAndCollectAttribute) {
            const newClickAndCollectAttribute = { ...clickAndCollectAttribute }
            newClickAndCollectAttribute.value = "true"
            firstLineItem.attributes = [
              ...firstLineItem.attributes.filter((attr: any) => attr.key !== "_click_and_collect"),
              newClickAndCollectAttribute,
            ]
          } else {
            firstLineItem?.attributes?.push({ key: "_click_and_collect", value: "true" })
          }
          requiresSaving = true
        } else if (!checkoutCC && lineItemCC) {
          lineItemsToSave?.forEach((item: any) => {
            const clickAndCollectAttribute = item.attributes?.find((attr: any) => attr.key === "_click_and_collect")
            if (clickAndCollectAttribute) {
              // remove custom attribute from array
              item.attributes = item.attributes.filter((attr: any) => attr.key !== "_click_and_collect")
              requiresSaving = true
            }
          })
        }

        if (requiresSaving) {
          lineItemsReplace({
            variables: {
              cartId: cart?.id,
              lines: lineItemsToSave,
            },
          })
            .then(({ data: { cartLinesUpdate: data, userErrors: errors } }) => {
              if (errors?.length) console.error(errors)
              if (data?.cart) {
                cart = data?.cart
              }
              setCart(cartNormaliser(cart))
              setStorage(settings?.keys?.cart, cart?.id)
              setStorage(settings?.keys?.market, cart?.buyerIdentity?.countryCode)
            })
            .catch(err => {
              console.error(err)
              setCart(cartNormaliser(cart))
              setStorage(settings?.keys?.cart, cart?.id)
              setStorage(settings?.keys?.market, cart?.buyerIdentity?.countryCode)
            })
        } else {
          setCart(cartNormaliser(cart))
          setStorage(settings?.keys?.cart, cart?.id)
          setStorage(settings?.keys?.market, cart?.buyerIdentity?.countryCode)
        }
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [cartNormaliser, setStorage, settings.keys.cart, settings.keys.market]
  )

  const createCart = useCallback(
    async (countryCode = "AU") => {
      try {
        const existingCart = await getCart()

        if (!existingCart?.id || Object.keys(existingCart).length < 1) {
          const {
            data: {
              cartCreate: { cart },
            },
          } = await cartCreate({
            variables: {
              countryCode,
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
            },
          })
          if (cart) saveCart(cart)
        } else {
          saveCart(existingCart)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(settings.keys.cart)
      }
    },
    [getCart, saveCart, cartCreate, removeStorage, settings.keys]
  )

  const giftRegistryCheckoutHandler = useCallback(async () => {
    let registryItemsInCart = false
    let addressPrefill = false

    const hasRegistryProduct = cart?.lines?.filter(item =>
      item.attributes.some(attr => attr.key === "registryProduct" && attr.value === "true")
    )

    if (hasRegistryProduct?.length > 0) {
      registryItemsInCart = true
    }

    if (registryItemsInCart === true) {
      registryItemsInCart = cart?.lines
      if (cart?.lines?.length > hasRegistryProduct?.length) {
        addressPrefill = false
      } else {
        addressPrefill = true
      }
    }

    return { registryItemsInCart, addressPrefill }
  }, [cart])

  const gotoCheckout = useCallback(
    async (e?: MouseEvent) => {
      if (e) e.preventDefault()

      if (!cart) return

      const registryState = await giftRegistryCheckoutHandler();

      const swymCheckoutURL = await customerRegistryPurchase(registryState?.addressPrefill, registryState?.registryItemsInCart, cart)

      if (swymCheckoutURL && swymCheckoutURL?.status === "success") {
        if (e?.ctrlKey || e?.metaKey) {
          window.open(swymCheckoutURL.body.invoiceUrl, "_blank")
        } else {
          window.location.replace(swymCheckoutURL.body.invoiceUrl)
        }
      } else {
        if (customer?.email) {
          try {
            const response = await callFunction("checkout-multipass", {
              customerEmail: customer.email,
              checkoutUrl: cart.checkoutUrl,
            })
  
            const url = response.status !== "error" && response.body.includes("https://") ? response.body : cart.checkoutUrl
            history.pushState(null, "", window.location.href)
            window.location.replace(url)
          } catch (e) {
            history.pushState(null, "", window.location.href)
            window.location.replace(cart.checkoutUrl)
          }
        } else {
          history.pushState(null, "", window.location.href)
          window.location.replace(cart.checkoutUrl)
        }
      }
      
    },
    [callFunction, cart, customer]
  )

  // const giftRegistryCheckoutHandler = useCallback(async () => {
  //   let registryItemsInCart = false
  //   let addressPrefill = false

  //   const hasRegistryProduct = cart?.lines?.filter(item =>
  //     item.attributes.some(attr => attr.key === "registryProduct" && attr.value === "true")
  //   )

  //   if (hasRegistryProduct?.length > 0) {
  //     registryItemsInCart = true
  //   }

  //   if (registryItemsInCart === true) {
  //     registryItemsInCart = cart?.lines
  //     if (cart?.lines?.length > hasRegistryProduct?.length) {
  //       addressPrefill = false
  //     } else {
  //       addressPrefill = true
  //     }
  //   }

  //   return { registryItemsInCart, addressPrefill }
  // }, [cart])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      cart,
      countryCode,
      currencyCode,
      loading,
      setLoading,
      gotoCheckout,
      saveCart,
      createCart,
    }),
    [id, url, count, cart, countryCode, currencyCode, gotoCheckout, saveCart, createCart]
  )

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCartContext = (): ContextProps => ({ ...React.useContext(CartContext) } as ContextProps)